import React from "react"
import { graphql } from "gatsby"
//geschweifte klammern heißt nur eine spezifische komponete aus dem plugin werden importiert
import * as style from "../pages/about.module.sass"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
const ErrorPage = ({ data }) => {
  return (
    <Layout>
      <div
        style={{
          height: "var(--spacing-120)",
        }}
      />
      <GatsbyImage
        className={style.img}
        image={data.noel.childImageSharp.gatsbyImageData}
        alt="noel"
      ></GatsbyImage>
      <div className={style.text}>
        {" "}
        <p>
          Sorry something when wrong. But now that your´re here: Welcome. I am a
          communication designer with a background in psychology. I create three
          dimensional objects and spaces - both digitally and physically. My
          work focuses around light, texture and feel.
          <br></br>
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany.
        </p>
        <div className={style.box} />
      </div>
    </Layout>
  )
}

export default ErrorPage

export const Head = () => {
  return (
    <>
      <title>AycaNoel</title>
      <meta
        name="description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
      <meta property="og:url" content="aycanoel.com" />
      <meta property="og:site_name" content="AycaNoel" />
      <meta
        property="og:description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
    </>
  )
}
export const query = graphql`
  query {
    noel: file(relativePath: { eq: "aboutaycanoel.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    laura: file(relativePath: { eq: "laura.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    file(relativePath: { eq: "aboutaycanoel.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
